export const COLOR_THEME = {
  primary: "#4F7CAC",
  textOnDark: "#FFFFFF",
  accent1: '#EE6C4D'
};

export const TYPOGRAPHY = {
  primary: "Yrsa",
};

export const FONT_SIZE_THEME = {
  title: "50px",
  header: "30px",
  body: "20px",
  small: "15px",
};

export const SPACING = {
  small: "10px",
  medium: "20px",
  large: "30px",
};

export const BORDER_RADIUS = {
  medium: "10px",
};
