import React, { useState } from "react";
import logo from "./antilaterallogo.svg";
import eventstreamanalysisimage from "./eventstreamanalysis.png";
import "./App.css";
import {
  BORDER_RADIUS,
  COLOR_THEME,
  FONT_SIZE_THEME,
  SPACING,
  TYPOGRAPHY,
} from "./constants";
import {
  DataBlock,
  PatternMap,
  UserBanStatus,
  generateDataAndConstructPatterns,
  getAllEventsWithPatternPrefix,
} from "./utils/analytics";
import CurrentEventPath from "./components/CurrentEventPath";
import EventMenu from "./components/EventMenu";
import EventList from "./components/EventList";
import DatasetView from "./components/DatasetView";

function App() {
  const [dataBlock, setDataBlock] = useState<DataBlock | null>(null);
  const [patternMap, setPatternMap] = useState<PatternMap | null>(null);
  const [userBanStatus, setUserBanStatus] = useState<UserBanStatus>({});
  const [selectedPattern, setSelectedPattern] = useState<string>("");
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{
          fontFamily: TYPOGRAPHY.primary,
          fontSize: FONT_SIZE_THEME.header,
          display: "flex",
          padding: SPACING.medium,
          paddingBottom: SPACING.small,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: 60, marginBottom: -20 }}>
            <img src={logo} alt="antilateral logo" style={{ width: "100%" }} />
          </div>
          <p style={{ lineHeight: 0 }}>antilateral</p>
        </div>
      </div>
      <div style={{ flex: 1, backgroundColor: COLOR_THEME.primary }}>
        <div
          style={{
            display: "flex",
            padding: SPACING.medium,
          }}
        >
          <div style={{ padding: SPACING.medium, flex: 1 }}>
            <p
              style={{
                fontSize: FONT_SIZE_THEME.title,
                color: COLOR_THEME.textOnDark,
                fontFamily: TYPOGRAPHY.primary,
              }}
            >
              Stop bad actors on your platform with your existing event streams
            </p>
          </div>
          <div style={{ flex: 2, display: "flex" }}>
            <div
              style={{
                margin: SPACING.large,
                padding: SPACING.medium,
                paddingTop: SPACING.large,
                paddingBottom: SPACING.large,
                backgroundColor: "white",
                borderRadius: BORDER_RADIUS.medium,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                <img
                  src={eventstreamanalysisimage}
                  style={{ height: "100%", width: "100%" }}
                  alt="Diagram of event stream analysis"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              margin: SPACING.medium,
              backgroundColor: "white",
              borderRadius: BORDER_RADIUS.medium,
              padding: SPACING.medium,
            }}
          >
            <p
              style={{
                fontSize: FONT_SIZE_THEME.header,
                fontFamily: TYPOGRAPHY.primary,
              }}
            >
              antilateral in-browser demo
            </p>
            {!patternMap && (
              <>
                <p
                  style={{
                    fontSize: FONT_SIZE_THEME.body,
                    fontFamily: TYPOGRAPHY.primary,
                  }}
                >
                  Try it out right here in your browser with our sample dataset.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      padding: SPACING.small,
                      backgroundColor: COLOR_THEME.accent1,
                      color: COLOR_THEME.textOnDark,
                      fontFamily: TYPOGRAPHY.primary,
                      fontSize: FONT_SIZE_THEME.body,
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      // console.log("selectedFile");
                      // const fileText = await selectedFile?.text();
                      // console.log(fileText);
                      const {
                        patterns: patternMap,
                        userBanStatus,
                        dataBlock,
                      } = generateDataAndConstructPatterns();
                      setPatternMap(patternMap);
                      setUserBanStatus(userBanStatus);
                      setDataBlock(dataBlock);
                    }}
                  >
                    load sample dataset
                  </button>
                </div>
              </>
            )}
            {patternMap && (
              <>
                {dataBlock && <DatasetView dataBlock={dataBlock} />}
                <div style={{ display: "flex" }}>
                  <CurrentEventPath
                    pattern={selectedPattern}
                    patternMap={patternMap}
                    userBanStatus={userBanStatus}
                    setSelectedPattern={setSelectedPattern}
                  />
                </div>
                <EventMenu
                  pattern={selectedPattern}
                  patternMap={patternMap}
                  setSelectedPattern={setSelectedPattern}
                  userBanStatus={userBanStatus}
                />
                <EventList
                  eventNestedList={getAllEventsWithPatternPrefix(
                    selectedPattern,
                    patternMap
                  ).slice(0, 100)}
                  userBanStatus={userBanStatus}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
