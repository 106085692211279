import { useState } from "react";
import { Event, UserBanStatus } from "../utils/analytics";
import {
  BORDER_RADIUS,
  FONT_SIZE_THEME,
  SPACING,
  TYPOGRAPHY,
} from "../constants";

function EventGroup({ events, banned }: { events: Event[]; banned: boolean }) {
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        backgroundColor: hovered ? "#eee" : undefined,
      }}
      onClick={() => setExpanded(!expanded)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          marginLeft: `-${SPACING.medium}`,
          paddingLeft: SPACING.medium,
        }}
      >
        <div style={{ flex: 1 }}>
          <p>{events[0].timestamp.toLocaleString()}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p>{events[0].user}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p>{banned ? "❌" : "✅"}</p>
        </div>
      </div>
      {expanded && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {events.map((event, i) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                key={`event-${i}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #000",
                  padding: SPACING.small,
                  borderRadius: BORDER_RADIUS.medium,
                }}
              >
                <p
                  style={{
                    fontSize: FONT_SIZE_THEME.body,
                    fontFamily: TYPOGRAPHY.primary,
                    lineHeight: 0,
                  }}
                >
                  {event.eventType.name}
                </p>
                <p
                  style={{
                    fontSize: FONT_SIZE_THEME.small,
                    fontFamily: TYPOGRAPHY.primary,
                    lineHeight: 0,
                  }}
                >{`${event.timestamp.toLocaleString()}`}</p>
              </div>
              {i < events.length - 1 && (
                <div
                  style={{
                    paddingLeft: SPACING.small,
                    paddingRight: SPACING.small,
                  }}
                >{`->`}</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default function EventList({
  eventNestedList,
  userBanStatus,
}: {
  eventNestedList: Event[][];
  userBanStatus: UserBanStatus;
}) {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          <p style={{ fontWeight: "bold" }}>time</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ fontWeight: "bold" }}>userID</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ fontWeight: "bold" }}>ban status</p>
        </div>
      </div>
      {eventNestedList.map((eventList) => (
        <div
          style={{ display: "flex", flexDirection: "column" }}
          key={`event-section-${eventList[0].user}-${eventList[0].timestamp}`}
        >
          <EventGroup
            events={eventList}
            banned={userBanStatus[eventList[0].user]}
          />
        </div>
      ))}
    </>
  );
}
