type EventType = {
  name: string;
  id: number;
};

export type Event = {
  timestamp: Date;
  eventType: EventType;
  user: string;
};

export type UserBanStatus = {
  [user: string]: boolean;
};

export type DataBlock = {
  events: Event[];
  userBanStatus: UserBanStatus;
};

const NUM_USERS = 300;
const NUM_EVENTS = 10000;

export const EVENTS = [
  { name: "login", id: 0 },
  { name: "send_message", id: 1 },
  { name: "make_post", id: 2 },
  { name: "send_friend_request", id: 3 },
  { name: "accept_friend_request", id: 4 },
  { name: "receive_message", id: 5 },
];

type EventNameMap = string[];

export function getEventNameMap(): EventNameMap {
  return EVENTS.map((e) => e.name);
}

export function getAllEventsWithPatternPrefix(
  pattern: string,
  patternMap: PatternMap
) {
  const userIDTimestampMap: { [key: string]: Event[] } = {};
  for (const patternCode in patternMap) {
    if (patternCode.startsWith(pattern)) {
      const nestedEventsList = patternMap[patternCode];
      for (const eventList of nestedEventsList) {
        if (eventList.length > 0) {
          const firstEvent = eventList[0];
          const key = `${firstEvent.user}-${firstEvent.timestamp}`;
          if (userIDTimestampMap[key] === undefined) {
            userIDTimestampMap[key] = eventList;
          } else if (eventList.length > userIDTimestampMap[key].length) {
            // only replace if length is longer
            userIDTimestampMap[key] = eventList;
          }
        }
      }
    }
  }
  // order in reverse chronological order
  return Object.values(userIDTimestampMap).sort((a, b) => {
    return b[0].timestamp.getTime() - a[0].timestamp.getTime();
  });
}

export function generateData(): DataBlock {
  // randomly assign ban state to users
  const banned = Array.from(Array(NUM_USERS)).map(() => Math.random() > 0.5);
  const data = [];
  const userBanStatus: { [key: string]: boolean } = {};
  for (let i = 0; i < NUM_EVENTS; i++) {
    const eventType = EVENTS[Math.floor(Math.random() * EVENTS.length)];
    const userIndex = Math.floor(Math.random() * NUM_USERS);
    const user = `user${userIndex + 1}`;
    // randomly generate a timestamp between now and 1 day ago
    data.push({
      timestamp: new Date(
        Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24)
      ),
      eventType,
      user,
    });

    if (userBanStatus[user] === undefined) {
      const ban = banned[userIndex];
      userBanStatus[user] = ban;
    }
  }
  return {
    events: data.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
    userBanStatus,
  };
}

export type PatternMap = {
  [pattern: string]: Event[][];
};

// 1 hour in milliseconds
const PATTERN_LENGTH = 1000 * 60 * 60;

export function constructPatterns(data: DataBlock) {
  const userEvents: { [user: string]: Event[] } = {};
  const patterns: PatternMap = {};
  for (const event of data.events) {
    if (userEvents[event.user] === undefined) {
      userEvents[event.user] = [];
    }
    userEvents[event.user].push(event);
    let truncateIndex = 0;
    for (const e of userEvents[event.user]) {
      if (e.timestamp.getTime() < event.timestamp.getTime() - PATTERN_LENGTH) {
        truncateIndex++;
      }
    }
    userEvents[event.user] = userEvents[event.user].slice(truncateIndex);
    for (let i = userEvents[event.user].length - 1; i >= 0; i--) {
      const patternCode = userEvents[event.user]
        .slice(i)
        .map((e) => e.eventType.id)
        .join("");
      if (patterns[patternCode] === undefined) {
        patterns[patternCode] = [];
      }
      patterns[patternCode].push(userEvents[event.user].slice(i));
    }
  }
  return patterns;
}

export function generateDataAndConstructPatterns(): {
  patterns: PatternMap;
  userBanStatus: UserBanStatus;
  dataBlock: DataBlock;
} {
  const data = generateData();
  const patterns = constructPatterns(data);

  return { patterns, userBanStatus: data.userBanStatus, dataBlock: data };
}
