import { useMemo, useState } from "react";
import { PatternMap, UserBanStatus, getEventNameMap } from "../utils/analytics";
import { FONT_SIZE_THEME, SPACING, TYPOGRAPHY } from "../constants";

type EventToOccurrenceMap = {
  [eventID: number]: {
    bannedCount: number; // total occurrences associated with banned users
    totalCount: number; // total occurrences associated with any user
  };
};

export default function EventMenu({
  pattern,
  patternMap,
  setSelectedPattern,
  userBanStatus,
}: {
  pattern: string;
  patternMap: PatternMap;
  setSelectedPattern: (newPattern: string) => void;
  userBanStatus: UserBanStatus;
}) {
  const [hoveringEventID, setHoveringEventID] = useState<number | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const possibleEvents: EventToOccurrenceMap = useMemo(() => {
    const allKeys = Object.keys(patternMap);
    const keysWithPrefix = allKeys
      .filter((key) => key.startsWith(pattern))
      .filter((key) => key.length > pattern.length);
    const eventToOccurrenceMap: EventToOccurrenceMap = {};
    for (const key of keysWithPrefix) {
      const eventID: number = parseInt(key[pattern.length]);
      if (!eventToOccurrenceMap[eventID]) {
        eventToOccurrenceMap[eventID] = {
          bannedCount: 0,
          totalCount: 0,
        };
      }
      const nestedEventList = patternMap[key];
      for (const eventList of nestedEventList) {
        const userID = eventList[0].user;
        if (userBanStatus[userID]) {
          eventToOccurrenceMap[eventID].bannedCount += 1;
        }
        eventToOccurrenceMap[eventID].totalCount += 1;
      }
    }
    return eventToOccurrenceMap;
  }, [pattern, patternMap, userBanStatus]);

  return (
    <>
      <button onClick={() => setMenuOpen(!menuOpen)}>Add event</button>
      {menuOpen && (
        <div>
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              border: "1px solid #000",
            }}
            onBlur={() => setMenuOpen(false)}
          >
            {Object.keys(possibleEvents)
              .sort(
                (a, b) =>
                  // put the highest risk events first
                  possibleEvents[parseInt(b)].bannedCount /
                    possibleEvents[parseInt(b)].totalCount -
                  possibleEvents[parseInt(a)].bannedCount /
                    possibleEvents[parseInt(a)].totalCount
              )
              .map((eventID, i) => (
                <div
                  key={`event-${i}`}
                  onClick={() => {
                    setSelectedPattern(pattern + eventID);
                    setMenuOpen(false);
                  }}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      hoveringEventID === parseInt(eventID)
                        ? "#eee"
                        : undefined,
                    borderBottom: "1px solid #eee",
                    padding: SPACING.small,
                  }}
                  onMouseEnter={() => setHoveringEventID(parseInt(eventID))}
                  onMouseLeave={() => setHoveringEventID(null)}
                >
                  <p
                    style={{
                      fontFamily: TYPOGRAPHY.primary,
                      fontSize: FONT_SIZE_THEME.body,
                      lineHeight: 0,
                    }}
                  >
                    {getEventNameMap()[parseInt(eventID)]}
                  </p>
                  <p
                    style={{
                      fontFamily: TYPOGRAPHY.primary,
                      fontSize: FONT_SIZE_THEME.small,
                      lineHeight: 0,
                    }}
                  >{`${possibleEvents[parseInt(eventID)].totalCount}x, ${
                    Math.round(
                      (possibleEvents[parseInt(eventID)].bannedCount /
                        possibleEvents[parseInt(eventID)].totalCount) *
                        10000
                    ) / 100
                  }%`}</p>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
