import { useMemo } from "react";
import {
  BORDER_RADIUS,
  FONT_SIZE_THEME,
  SPACING,
  TYPOGRAPHY,
} from "../constants";
import { PatternMap, UserBanStatus, getEventNameMap } from "../utils/analytics";

type PathStatistics = {
  bannedCount: number; // total occurrences associated with banned users
  totalCount: number; // total occurrences associated with any user
};

export default function CurrentEventPath({
  pattern,
  patternMap,
  userBanStatus,
  setSelectedPattern,
}: {
  pattern: string;
  patternMap: PatternMap;
  userBanStatus: UserBanStatus;
  setSelectedPattern: (newPattern: string) => void;
}) {
  const eventNameMap = getEventNameMap();
  const occurenceStats = useMemo(() => {
    const pathStatistics: PathStatistics = {
      bannedCount: 0,
      totalCount: 0,
    };
    const allKeys = Object.keys(patternMap);
    const keysWithPrefix = allKeys
      .filter((key) => key.startsWith(pattern))
      .filter((key) => key.length >= pattern.length);
    for (const key of keysWithPrefix) {
      const nestedEventList = patternMap[key];
      for (const eventList of nestedEventList) {
        const userID = eventList[0].user;
        if (userBanStatus[userID]) {
          pathStatistics.bannedCount += 1;
        }
        pathStatistics.totalCount += 1;
      }
    }
    return pathStatistics;
  }, [pattern, patternMap, userBanStatus]);
  return (
    <div>
      <p style={{ fontFamily: TYPOGRAPHY.primary }}>searching for:</p>
      {pattern.length === 0 && (
        <p style={{ fontFamily: TYPOGRAPHY.primary }}>all patterns. Use the "Add event" button below to narrow your search.</p>
      )}
      <div style={{ display: "flex" }}>
        {pattern.split("").map((char, i) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              key={`path-${i}`}
              style={{
                border: "1px solid black",
                padding: SPACING.small,
                borderRadius: BORDER_RADIUS.medium,
                position: "relative",
              }}
            >
              <p
                style={{
                  fontSize: FONT_SIZE_THEME.small,
                  fontFamily: TYPOGRAPHY.primary,
                  lineHeight: 0,
                }}
              >
                {eventNameMap[parseInt(char)]}
              </p>
              {i === pattern.length - 1 && (
                <button
                  style={{
                    position: "absolute",
                    top: -6,
                    right: -6,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedPattern(pattern.slice(0, -1));
                  }}
                >
                  x
                </button>
              )}
            </div>
            {i < pattern.length - 1 && (
              <div
                style={{
                  paddingLeft: SPACING.small,
                  paddingRight: SPACING.small,
                }}
              >{`->`}</div>
            )}
          </div>
        ))}
      </div>
      <p style={{ fontFamily: TYPOGRAPHY.primary }}>{`Occurred ${
        occurenceStats.totalCount
      }x, ${
        Math.round(
          (occurenceStats.bannedCount / occurenceStats.totalCount) * 10000
        ) / 100
      }% banned`}</p>
    </div>
  );
}
