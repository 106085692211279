import { FONT_SIZE_THEME, TYPOGRAPHY } from "../constants";
import { DataBlock } from "../utils/analytics";

export default function DatasetView({ dataBlock }: { dataBlock: DataBlock }) {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: 300,
        }}
      >
        <p style={{ fontFamily: TYPOGRAPHY.primary }}>
          raw event data (500 most recent)
        </p>
        <div style={{ display: "flex", minHeight: 30 }}>
          <div
            style={{
              flex: 1,
              fontFamily: TYPOGRAPHY.primary,
              fontSize: FONT_SIZE_THEME.body,
              lineHeight: 0,
              fontWeight: "bold",
            }}
          >
            timestamp
          </div>
          <div
            style={{
              flex: 1,
              fontFamily: TYPOGRAPHY.primary,
              fontSize: FONT_SIZE_THEME.body,
              lineHeight: 0,
              fontWeight: "bold",
            }}
          >
            event type
          </div>
          <div
            style={{
              flex: 1,
              fontFamily: TYPOGRAPHY.primary,
              fontSize: FONT_SIZE_THEME.body,
              lineHeight: 0,
              fontWeight: "bold",
            }}
          >
            userID
          </div>
        </div>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {dataBlock.events
            .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
            .slice(0, 500)
            .map((event) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  minHeight: 50,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    fontFamily: TYPOGRAPHY.primary,
                    fontSize: FONT_SIZE_THEME.body,
                    lineHeight: 0,
                  }}
                >
                  {event.timestamp.toLocaleString()}
                </div>
                <div
                  style={{
                    flex: 1,
                    fontFamily: TYPOGRAPHY.primary,
                    fontSize: FONT_SIZE_THEME.body,
                    lineHeight: 0,
                  }}
                >
                  {event.eventType.name}
                </div>
                <div
                  style={{
                    flex: 1,
                    fontFamily: TYPOGRAPHY.primary,
                    fontSize: FONT_SIZE_THEME.body,
                    lineHeight: 0,
                  }}
                >
                  {event.user}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: 300,
        }}
      >
        <p style={{ fontFamily: TYPOGRAPHY.primary }}>user ban data</p>
        <div style={{ display: "flex", minHeight: 30 }}>
          <div
            style={{
              flex: 1,
              fontFamily: TYPOGRAPHY.primary,
              fontSize: FONT_SIZE_THEME.body,
              lineHeight: 0,
              fontWeight: "bold",
            }}
          >
            userID
          </div>
          <div
            style={{
              flex: 1,
              fontFamily: TYPOGRAPHY.primary,
              fontSize: FONT_SIZE_THEME.body,
              lineHeight: 0,
              fontWeight: "bold",
            }}
          >
            ban status
          </div>
        </div>
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {Object.keys(dataBlock.userBanStatus).sort().map((userID) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minHeight: 50,
              }}
            >
              <div
                style={{
                  flex: 1,
                  fontFamily: TYPOGRAPHY.primary,
                  fontSize: FONT_SIZE_THEME.body,
                  lineHeight: 0,
                }}
              >
                {userID}
              </div>
              <div
                style={{
                  flex: 1,
                  fontFamily: TYPOGRAPHY.primary,
                  fontSize: FONT_SIZE_THEME.body,
                  lineHeight: 0,
                }}
              >
                {dataBlock.userBanStatus[userID] ? "banned" : "not banned"}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
